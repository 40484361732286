import React from "react";
import "./Merch.css";

function Merch() {
  return (
    <div id="Merch">
      <a className="Merch-link" href="https://sniper66.bandcamp.com/merch">
        <img
          className="Merch-image"
          src={`https://s3.amazonaws.com/sniper66/${
            window.innerWidth < 600 ? "mobile-" : ""
          }merch-store.png`}
          alt="Merch Link"
        />
      </a>
    </div>
  );
}
export default Merch;
