import React from "react";
import "./Home.css";

function Home() {
  return (
    <div id="Home">
      <main>
        <img
          className="splash_img"
          src={`https://s3.amazonaws.com/sniper66/${
            window.innerWidth < 600 ? "mobile-" : ""
          }splash-photo-noXs.png`}
          alt="splash_img"
        />
      </main>
    </div>
  );
}
export default Home;
