import React, { useState } from "react";
import "./Contact.css";

function Contact(props) {
  const [status, setStatus] = useState("");
  const submitForm = (ev) => {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        setStatus("SUCCESS");
      } else {
        setStatus("ERROR");
      }
    };
    xhr.send(data);
  };

  return (
    <div id="Contact">
      <h1>
        <center>Contact</center>
      </h1>
      <form
        onSubmit={submitForm}
        action="https://formspree.io/f/xoqpllnb"
        method="POST"
      >
        <label>Name:</label>
        <input type="text" name="name" />
        <label>Email:</label>
        <input type="email" name="email" />
        <label>Message:</label>
        <textarea rows="10" type="text" name="message" />
        <button id="contact-button">Submit</button>
        {status === "SUCCESS" ? <p>Thanks!</p> : null}
        {status === "ERROR" && <p class="error">Ooops! There was an error.</p>}
      </form>
    </div>
  );
}

export default Contact;
